import React from 'react'
import { css } from '@emotion/core'
import Wrapper from '../components/wrapper'
import { Link } from 'gatsby'
import { minWidth } from '../breakpoints'

export default ({ url }) => (
  <Wrapper
    styles={css`
      padding-top: 1.25rem;
      text-align: center;
    `}
  >
    <div
      css={css`
        ${minWidth.medium} {
          display: flex;
          justify-content: space-between;
        }
      `}
    >
      <div
        css={css`
          margin-bottom: 1rem;

          ${minWidth.medium} {
            margin-bottom: 0;
          }
        `}
      >
        © {new Date().getFullYear()} - JoyJoy campers is onderdeel van{' '}
        <a target="_blank" rel="noopener noreferrer" href={url}>
          Actief Twente
        </a>
      </div>
      <Link to="/huurvoorwaarden">Huurvoorwaarden</Link>
    </div>
  </Wrapper>
)
