import React from 'react'
import { css } from '@emotion/core'
import { minWidth } from '../breakpoints'

export default ({ children, styles }) => (
  <div
    css={[
      css`
        margin: 0 auto;
        max-width: 1280px;
        padding-left: 20px;
        padding-right: 20px;

        ${minWidth.medium} {
          padding-left: 40px;
          padding-right: 40px;
        }
      `,
      styles,
    ]}
  >
    {children}
  </div>
)
