import React from 'react'
import { colors } from '../variables'
import { Link } from 'gatsby'
import font from '../fonts'
import { minWidth } from '../breakpoints'

const items = [
  { link: '/', label: 'Home' },
  { link: '/scandinavie', label: 'Scandinavië' },
  { link: '/balkan', label: 'Balkan' },
  { link: '/middellandse-zee', label: 'Middellandse Zee' },
  { link: '/oost-europa', label: 'Oost Europa' },
  { link: '/huurvoorwaarden', label: 'Huurvoorwaarden' },
]

const Line = ({ styles }) => (
  <div
    css={[
      {
        background: 'white',
        height: 3,
        transformOrigin: '100% 50%',
        transition: 'transform .3s cubic-bezier(0, 0, 0, 1)',
        width: 20,
        [minWidth.tablet]: {
          width: 24,
        },
      },
      styles,
    ]}
  />
)

export default class Navigation extends React.Component {
  state = {
    open: false,
  }

  render() {
    return (
      <div
        css={{
          display: 'flex',
        }}
      >
        <div
          css={{
            position: 'relative',
            zIndex: 3,
          }}
        >
          <div
            css={{
              alignItems: 'center',
              background: colors.lightBlue,
              display: 'flex',
              height: '100%',
              flexDirection: 'column',
              justifyContent: 'center',
              left: 0,
              opacity: this.state.open ? 1 : 0,
              overflowX: 'hidden',
              overflowY: 'scroll',
              padding: '4rem 20px',
              pointerEvents: this.state.open ? 'all' : 'none',
              position: 'fixed',
              textAlign: 'center',
              top: 0,
              transition: 'opacity .3s cubic-bezier(0, 0, 0, 1)',
              width: '100%',
              zIndex: 3,
            }}
          >
            {items.map((item, index) => (
              <Link
                css={{
                  color: colors.cyan,
                  fontFamily: font('Arvo'),
                  fontSize: '1.5rem',
                  fontWeight: 700,
                  marginBottom: 5,
                  textDecoration: 'none',
                  transition: 'transform .2s cubic-bezier(0, 0, 0, 1)',
                  ':hover': {
                    transform: 'scale(1.1)',
                  },
                  ':last-child': {
                    marginBottom: 0,
                  },
                  [minWidth.tablet]: {
                    marginBottom: 10,
                    ':last-child': {
                      marginBottom: 0,
                    },
                  },
                }}
                key={index}
                to={item.link}
              >
                {item.label}
              </Link>
            ))}
          </div>
        </div>
        <div
          css={{
            alignItems: 'center',
            background: 'black',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            height: 40,
            transition: 'background-color 0.2s ease-out',
            width: 40,
            zIndex: 4,
            [minWidth.tablet]: {
              height: 50,
              width: 50,
            },
            ':hover': {
              backgroundColor: colors.cyan,
            },
          }}
          onClick={() =>
            this.setState({ open: !this.state.open }, () =>
              this.props.onChange(this.state.open)
            )
          }
        >
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              height: 17,
              justifyContent: 'space-between',
              [minWidth.tablet]: {
                height: 21,
              },
            }}
          >
            <Line
              styles={
                this.state.open
                  ? {
                      transform:
                        'rotate(-45deg) translateX(-2px) translateY(-2px)',
                      [minWidth.tablet]: {
                        transform:
                          'rotate(-45deg) translateX(-3px) translateY(-2.5px)',
                      },
                    }
                  : {}
              }
            />
            <Line
              styles={[
                { transformOrigin: '50% 50%' },
                this.state.open ? { transform: 'scaleX(0)' } : {},
              ]}
            />
            <Line
              styles={
                this.state.open
                  ? {
                      transform:
                        'rotate(45deg) translateX(-2px) translateY(2px)',
                      [minWidth.tablet]: {
                        transform:
                          'rotate(45deg) translateX(-3px) translateY(2.5px)',
                      },
                    }
                  : {}
              }
            />
          </div>
        </div>
      </div>
    )
  }
}
