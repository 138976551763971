import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { css } from '@emotion/core'
import Wrapper from '../components/wrapper'
import Thin from '../components/thin'
import Phone from '../components/phone'
import font from '../fonts'
import { footerDistance } from '../distances'
import Button from './button'
import { minWidth } from '../breakpoints'
import Img from 'gatsby-image'

const Heading = ({ image }) => (
  <div
    css={css`
      margin-bottom: 1.5rem;

      ${minWidth.medium} {
        align-items: center;
        display: flex;
        margin-bottom: 1rem;
      }

      ${minWidth.laptop} {
        margin-bottom: 0;
      }
    `}
  >
    <Img
      style={{ flexShrink: '0' }}
      imgStyle={{ borderRadius: '50%' }}
      fixed={image}
    />
    <div
      css={css`
        ${minWidth.medium} {
          margin-left: 2.2rem;
        }
      `}
    >
      <h3
        css={css`
          margin: 0.5em 0 0.3em;

          ${minWidth.medium} {
            margin-top: 0;
          }
        `}
      >
        Heb je nog vragen?
      </h3>
      <Thin>Bel of mail ons gerust. We helpen je graag!</Thin>
    </div>
  </div>
)

const Actions = ({ site }) => (
  <div
    css={css`
      align-items: center;
      display: flex;
      flex-direction: column;
      width: 100%;

      ${minWidth.medium} {
        flex-direction: row;
        flex-shrink: 0;
        justify-content: center;
        width: auto;
      }

      ${minWidth.laptop} {
        margin-left: 2rem;
      }
    `}
  >
    <a
      href={`tel:${site.phoneFlat}`}
      css={css`
        align-items: center;
        color: black;
        display: flex;
        font-family: ${font('Arvo')};
        font-size: 1.4em;
        font-weight: 700;
        margin-bottom: 0.5rem;
        text-decoration: none;
        transition: transform 0.2s ease-out;

        &:hover {
          transform: translateY(-3px);
        }

        ${minWidth.medium} {
          margin: 0;
        }
      `}
    >
      <Phone color="black" />
      {site.phone}
    </a>
    <Button
      styles={css`
        width: 100%;

        ${minWidth.medium} {
          margin-left: 2.2rem;
          width: auto;
        }
      `}
      to={`mailto:${site.email}`}
    >
      E-mail ons
    </Button>
  </div>
)

export default () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            phone
            phoneFlat
            email
          }
        }
        about: file(relativePath: { eq: "about.jpg" }) {
          image: childImageSharp {
            fixed(width: 120, height: 120, quality: 80) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={({ about, site }) => (
      <Wrapper
        styles={css`
          margin-bottom: calc(-${footerDistance}px + 3.25rem);
          top: -${footerDistance}px;
          position: relative;
        `}
      >
        <div
          css={css`
            align-items: center;
            background: white;
            box-shadow: 0 2px 13px rgba(0, 0, 0, 0.1);
            display: flex;
            flex-direction: column;
            padding: 1.67rem 1.25rem;
            position: relative;
            text-align: center;
            z-index: 1;

            ${minWidth.medium} {
              padding: 1.67rem 2rem 2rem;
              text-align: left;
            }

            ${minWidth.laptop} {
              flex-direction: row;
              justify-content: space-between;
              padding: 1.67rem 2rem;
            }

            ${minWidth.full} {
              padding: 1.67rem 3.33rem;
            }
          `}
        >
          <Heading image={about.image.fixed} />
          <Actions site={site.siteMetadata} />
        </div>
      </Wrapper>
    )}
  />
)
