import React from 'react'
import ReactDOM from 'react-dom'
import { minWidth } from '../breakpoints'
import { Global, css } from '@emotion/core'

const formWidth = 700

export default class Overlay extends React.Component {
  constructor(props) {
    super(props)
    this.state = { visible: false }
  }

  componentDidMount() {
    requestAnimationFrame(() => {
      requestAnimationFrame(() => this.setState({ visible: true }))
    })
  }

  isIE = () =>
    typeof window === 'undefined'
      ? false
      : window.navigator.userAgent.indexOf('Trident/') > -1
  close = () => !this.state.visible && this.props.close()
  hide = () => this.setState({ visible: false })

  render() {
    return ReactDOM.createPortal(
      <>
        <Global
          styles={css`
            html,
            body {
              height: 100%;
              overflow-y: hidden;
            }
          `}
        />
        <div
          css={css`
            align-items: ${this.isIE() ? 'flex-start' : 'center'};
            display: flex;
            height: 100%;
            justify-content: center;
            left: 0;
            overflow-y: auto;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 100;

            ${minWidth.small} {
              padding: 20px;
            }

            ${minWidth.tablet} {
              padding: 60px;
            }
          `}
        >
          <div
            css={css`
              background: white;
              margin-bottom: auto;
              margin-top: auto;
              max-width: ${formWidth + 80}px;
              padding: 40px 20px 40px;
              position: relative;
              width: 100%;
              z-index: 101;
              opacity: ${this.state.visible ? 1 : 0};
              transform: scale(${this.state.visible ? 1 : 1.25});
              transition: opacity 0.2s ease-out, transform 0.2s ease-out;

              ${minWidth.tablet} {
                padding: 60px 60px 40px;
              }
            `}
          >
            <div
              css={css`
                color: black;
                cursor: pointer;
                display: flex;
                font-size: 40px;
                height: 50px;
                width: 50px;
                position: absolute;
                right: 0;
                top: 0;

                ${minWidth.tablet} {
                  font-size: 48px;
                  height: 60px;
                  width: 60px;
                }
              `}
              onClick={this.hide}
            >
              <div>×</div>
            </div>

            {this.props.children}
          </div>

          <div
            css={css`
              background: rgba(0, 0, 0, 0.8);
              height: 100%;
              left: 0;
              opacity: ${this.state.visible ? 1 : 0};
              position: fixed;
              top: 0;
              transition: opacity 0.2s ease-out;
              transition-delay: ${this.state.visible ? 0 : 0.2}s;
              width: 100%;
              z-index: 100;
            `}
            onClick={this.hide}
            onTransitionEnd={this.close}
          />
        </div>
      </>,
      document.querySelector('.overlay')
    )
  }
}
