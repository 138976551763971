import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Wrapper from './wrapper'
import Navigation from './navigation'
import Phone from './phone'
import { minWidth } from '../breakpoints'
import BookingButton from './booking-button'
import Button from './button'
import font from '../fonts'
import { colors } from '../variables'
import SEO from '../components/seo'

export default class Header extends React.Component {
  state = {
    menuOpen: false,
  }

  render() {
    const {
      background,
      seoTitle,
      seoDescription,
      showButton = true,
      title,
    } = this.props

    return (
      <StaticQuery
        query={graphql`
          query {
            site {
              siteMetadata {
                phone
                phoneFlat
                email
              }
            }
            logo: file(relativePath: { eq: "logo.png" }) {
              image: childImageSharp {
                fluid(maxWidth: 170, quality: 100) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        `}
        render={({ logo, site }) => (
          <div>
            <SEO
              title={seoTitle}
              description={seoDescription}
              noScroll={this.state.menuOpen}
            />
            <div
              css={{
                background: `url(${background.src}) ${colors.lightBlue} 50% / cover`,
                display: 'flex',
                marginBottom: '3rem',
                paddingBottom: 'calc(6vw - 20px)',
                position: 'relative',
              }}
            >
              <div
                css={{
                  background:
                    'linear-gradient(to bottom, rgba(0, 0, 0, .1), rgba(0, 0, 0, .4))',
                  height: '100%',
                  left: 0,
                  position: 'absolute',
                  top: 0,
                  width: '100%',
                  zIndex: 1,
                }}
              />
              <Wrapper
                styles={{
                  width: '100%',
                  zIndex: 2,
                }}
              >
                <div
                  css={{
                    alignItems: 'flex-start',
                    display: 'flex',
                  }}
                >
                  <Link
                    css={{
                      marginRight: 'auto',
                      width: 130,
                      [minWidth.tablet]: {
                        width: 170,
                      },
                    }}
                    to="/"
                  >
                    <Img
                      fluid={logo.image.fluid}
                      style={{ maxWidth: '100%' }}
                    />
                  </Link>
                  <div
                    css={{
                      alignItems: 'center',
                      display: 'flex',
                      marginTop: 20,
                      [minWidth.tablet]: {
                        marginTop: 30,
                      },
                    }}
                  >
                    <div
                      css={{
                        display: 'none',
                        [minWidth.tablet]: {
                          display: 'flex',
                          marginRight: 20,
                        },
                        [minWidth.laptop]: {
                          marginRight: 30,
                        },
                      }}
                    >
                      <a
                        css={{
                          alignItems: 'center',
                          color: 'white',
                          display: 'flex',
                          fontFamily: font('Arvo'),
                          fontSize: 20,
                          fontWeight: 700,
                          marginRight: 20,
                          textDecoration: 'none',
                          textShadow: '0 1px 2px rgba(0, 0, 0, .1)',
                          transition: 'transform 0.2s ease-out',
                          [minWidth.laptop]: {
                            marginRight: 30,
                          },
                          ':hover': {
                            transform: 'translateY(-3px)',
                          },
                        }}
                        href={`tel:${site.siteMetadata.phoneFlat}`}
                      >
                        <Phone />
                        {site.siteMetadata.phone}
                      </a>
                      <Button
                        color="white"
                        small
                        to={`mailto:${site.siteMetadata.email}`}
                      >
                        e-mail ons
                      </Button>
                    </div>
                    <Navigation
                      onChange={(menuOpen) => this.setState({ menuOpen })}
                    />
                  </div>
                </div>

                <div
                  css={{
                    margin: '0 auto',
                    maxWidth: 500,
                    textAlign: 'center',
                    [minWidth.tablet]: {
                      maxWidth: 840,
                    },
                  }}
                >
                  <h1
                    css={{
                      color: 'white',
                      margin: showButton ? '20px 0 40px' : '20px 0 80px',
                      textShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
                      wordBreak: 'break-word',
                      [minWidth.tablet]: {
                        margin: showButton ? '50px 0 80px' : '50px 0 100px',
                      },
                      [minWidth.laptop]: {
                        marginBottom: showButton ? 125 : 150,
                      },
                    }}
                  >
                    {title}
                  </h1>
                  {showButton && (
                    <BookingButton
                      styles={{
                        marginBottom: -25,
                      }}
                    >
                      Beschikbaarheid & boeken
                    </BookingButton>
                  )}
                </div>
              </Wrapper>
              <div
                css={{
                  bottom: 0,
                  left: 0,
                  overflow: 'hidden',
                  position: 'absolute',
                  transform: 'translateY(.5px)',
                  zIndex: 1,
                  width: '100%',
                }}
              >
                <svg
                  css={{
                    display: 'block',
                    height: '6.25vw',
                    width: '100vw',
                  }}
                  width="1600"
                  height="100"
                  viewBox="0 0 1600 100"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1600 100H0C266.667 33.333 533.333 0 800 0c266.667 0 533.333 33.333 800 100z"
                    fill="#FFF"
                  />
                </svg>
              </div>
            </div>
          </div>
        )}
      />
    )
  }
}
