import React from 'react'
import { css } from '@emotion/core'

export default ({ children, styles }) => (
  <p
    css={[
      css`
        color: #666;
        font-size: 1.33em;
        line-height: 1.25;
        margin-bottom: 1.2em;
        font-weight: 300;
      `,
      styles,
    ]}
  >
    {children}
  </p>
)
