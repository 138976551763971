import { css } from '@emotion/core'
import { minWidth } from './breakpoints'

const distance = 100

export const footerDistance = 120
export const margin = (direction, baseDistance = 0) => css`
  ${`margin-` + direction}: ${baseDistance + distance * 0.55}px;

  ${minWidth.tablet} {
    ${`margin-` + direction}: ${baseDistance + distance * 0.775}px;
  }

  ${minWidth.laptop} {
    ${`margin-` + direction}: ${baseDistance + distance}px;
  }
`
export const padding = (direction, baseDistance = 0) => css`
  ${`padding-` + direction}: ${baseDistance + distance * 0.55}px;

  ${minWidth.tablet} {
    ${`padding-` + direction}: ${baseDistance + distance * 0.775}px;
  }

  ${minWidth.laptop} {
    ${`padding-` + direction}: ${baseDistance + distance}px;
  }
`
