const breakpoints = {
  small: 480,
  medium: 640,
  tablet: 768,
  laptop: 1024,
  full: 1280,
}

export const minWidth = Object.keys(breakpoints).reduce((minWidth, name) => {
  minWidth[name] = `@media(min-width: ${breakpoints[name]}px)`

  return minWidth
}, {})
