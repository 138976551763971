import React from 'react'
import { Link } from 'gatsby'
import { colors, lineHeight } from '../variables'
import font from '../fonts'
import { css } from '@emotion/core'
import Color from 'color'

export default ({ children, color, small, styles, to, onClick }) => {
  const backgroundColor = (color) => {
    switch (color) {
      case 'black':
      case 'white':
        return color
      case 'green':
        return colors.green
      default:
        return colors.orange
    }
  }
  const shadowColor = 'rgba(0, 0, 0, 0.1)'
  const textColor = (color) => {
    switch (color) {
      case 'white':
        return 'black'
      default:
        return 'white'
    }
  }

  const base = css`
    background: ${backgroundColor(color)};
    box-shadow: 0 2px 8px ${shadowColor};
    color: ${textColor(color)};
    cursor: pointer;
    display: inline-flex;
    font-family: ${font('Arvo')};
    font-size: 0.9em;
    font-weight: bold;
    justify-content: center;
    line-height: ${lineHeight};
    padding: ${small ? '0.85em' : '1.15em'} 1.95em;
    text-decoration: none;
    text-transform: lowercase;
    transition: background-color 0.2s ease-out, box-shadow 0.2s ease-out,
      transform 0.2s ease-out;

    &:hover {
      background: ${color === 'white'
        ? 'white'
        : Color(backgroundColor(color)).mix(Color('black'), 0.1).string()};
      box-shadow: 0px 5px 16px ${shadowColor};
      transform: translateY(-3px);
    }
  `

  if (to) {
    if (/^\/(?!\/)/.test(to)) {
      return (
        <Link css={[base, styles]} to={to}>
          {children}
        </Link>
      )
    } else {
      return (
        <a
          target={/^(mailto|tel):/.test(to) ? '_self' : '_blank'}
          rel="noopener noreferrer"
          css={[base, styles]}
          href={to}
        >
          {children}
        </a>
      )
    }
  } else {
    return (
      <div css={[base, styles]} onClick={onClick}>
        {children}
      </div>
    )
  }
}
