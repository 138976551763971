import React from 'react'

export default ({ color }) => (
  <svg
    css={{
      marginRight: 10,
    }}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.532.787l2.204 2.204c1.05 1.049 1.05 2.833 0 3.882L6.847 8.657c1.889 2.518 5.981 6.506 8.395 8.394l1.888-1.784a2.66 2.66 0 0 1 3.778 0l2.203 2.204a2.66 2.66 0 0 1 0 3.778l-1.888 1.993C16.29 28.174-4.171 7.607.76 2.676L2.65.787c1.05-1.05 2.833-1.05 3.882 0z"
      fill={color === 'black' ? '#000' : '#FFF'}
    />
  </svg>
)
