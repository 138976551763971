const fonts = [
  '-apple-system',
  'BlinkMacSystemFont',
  'Segoe UI',
  'Roboto',
  'Oxygen',
  'Ubuntu',
  'Cantarell',
  'Fira Sans',
  'Droid Sans',
  'Helvetica Neue',
  'sans-serif',
]

export default (font, render = true) => {
  const newFonts = [...fonts]

  newFonts.unshift(font)

  return render ? newFonts.join(', ') : newFonts
}
