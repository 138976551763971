import React from 'react'
import { Global, css } from '@emotion/core'
import Footer from '../components/footer'

export default ({ children }) => (
  <>
    <Global
      styles={css`
        html {
          -ms-overflow-style: -ms-autohiding-scrollbar;
          -webkit-font-smoothing: antialiased;

          &.no-scroll,
          &.no-scroll body {
            height: 100%;
            overflow-y: hidden;
          }
        }

        p:last-child {
          margin-bottom: 0;
        }
      `}
    />
    {children}
    <Footer />
    <div className="overlay" />
  </>
)
