import React from 'react'
import Button from './button'
import { minWidth } from '../breakpoints'
import Overlay from './overlay'
import { css } from '@emotion/core'

export default class BookingButton extends React.Component {
  state = { open: false }

  open = () => this.setState({ open: true })
  close = () => this.setState({ open: false })

  render() {
    return (
      <>
        <Button onClick={this.open} {...this.props}>
          {this.props.children}
        </Button>

        {this.state.open && (
          <Overlay close={this.close}>
            <iframe
              css={css`
                border: 0;
                margin: 0;
                min-height: 935px;
                width: 100%;

                ${minWidth.medium} {
                  min-height: 845px;
                }
              `}
              title="booking"
              src="https://twentescooter.recras.nl/contactformulier/index/id/98"
            />
          </Overlay>
        )}
      </>
    )
  }
}
