import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { css } from '@emotion/core'
import Wrapper from '../components/wrapper'
import Copyright from '../components/copyright'
import { Link } from 'gatsby'
import Cta from '../components/cta'
import { colors } from '../variables'
import { minWidth } from '../breakpoints'

const Curve = () => (
  <div
    css={css`
      bottom: 100%;
      left: 0;
      overflow: hidden;
      pointer-events: none;
      position: absolute;
      width: 100%;
    `}
  >
    <svg
      css={css`
        display: block;
        fill: ${colors.cyan};
        height: 3.75vw;
        transform: translateY(0.5px);
        width: 100vw;
      `}
      viewBox="0 0 1600 60"
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m0 0c271.438038 40 538.028969 60 799.772792 60 261.743818 0 528.486228-20 800.227208-60v60h-1600z" />
    </svg>
  </div>
)

const Widget = ({ title, children, styles }) => (
  <div
    css={[
      css`
        ${minWidth.small} {
          flex-grow: 0;
          flex-shrink: 0;
        }

        ul {
          list-style: none;
          margin: 0;

          ${minWidth.full} {
            display: flex;
            flex-wrap: wrap;
          }
        }

        li {
          margin: 0;

          ${minWidth.full} {
            flex: none;
            padding-right: 1em;
            width: 50%;
          }
        }
      `,
      styles,
    ]}
  >
    <h3
      css={css`
        color: white;
        font-size: 1.5em;
        margin-bottom: 0.8em;
      `}
    >
      {title}
    </h3>
    {children}
  </div>
)

export default () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            companyUrl
            email
          }
        }
      }
    `}
    render={({ site }) => (
      <div
        css={css`
          position: relative;
        `}
      >
        <div
          css={css`
            background: ${colors.cyan};
            padding-bottom: 2.5rem;
            position: relative;
          `}
        >
          <Curve />
          <Cta />
          <div
            css={css`
              color: white;
              font-size: 0.9em;
              line-height: 1.9;

              a {
                color: white;
                transition: color 0.2s ease-out;

                &:hover {
                  color: ${colors.green};
                }
              }
            `}
          >
            <Wrapper>
              <div
                css={css`
                  border-bottom: 2px solid rgba(238, 238, 238, 0.1);
                  padding-bottom: 2rem;

                  ${minWidth.small} {
                    display: flex;
                    flex-wrap: wrap;
                  }

                  ${minWidth.medium} {
                    justify-content: space-between;
                  }

                  ${minWidth.tablet} {
                    padding-bottom: 3.25rem;
                  }
                `}
              >
                <Widget
                  title="Over JoyJoy campers"
                  styles={css`
                    margin-bottom: 2rem;
                    width: 100%;

                    ${minWidth.medium} {
                      margin-bottom: 0;
                      width: 35%;
                    }
                  `}
                >
                  <p>
                    JoyJoy campers worden verhuurd door{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={site.siteMetadata.companyUrl}
                    >
                      Actief Twente
                    </a>
                    . Actief Twente verhuurt naast luxe camperbussen sinds 2015
                    fietsen, scooters, solexen en kano’s in Twente.
                  </p>
                </Widget>
                <Widget
                  title="Contact"
                  styles={css`
                    margin-bottom: 2rem;
                    width: 50%;

                    ${minWidth.small} {
                      margin-bottom: 0;
                    }

                    ${minWidth.medium} {
                      width: 30%;
                    }
                  `}
                >
                  <strong>JoyJoy campers</strong>
                  <ul>
                    <li>Denekamperstraat 49</li>
                    <li>tel. 0541 - 29 98 21</li>
                    <li>7588 PS Beuningen</li>
                    <li>
                      <a href={`mailto:${site.siteMetadata.email}`}>
                        {site.siteMetadata.email}
                      </a>
                    </li>
                  </ul>
                </Widget>
                <Widget
                  title="Onze routes"
                  styles={css`
                    width: 50%;

                    ${minWidth.medium} {
                      width: 25%;
                    }
                  `}
                >
                  <ul>
                    <li>
                      <Link to="/middellandse-zee">Middellandse zee</Link>
                    </li>
                    <li>
                      <Link to="/balkan">Balkan</Link>
                    </li>
                    <li>
                      <Link to="/scandinavie">Scandinavië</Link>
                    </li>
                    <li>
                      <Link to="/oost-europa">Oost Europa</Link>
                    </li>
                    <li>
                      <Link to="/ultiem-europa">Ultiem Europa</Link>
                    </li>
                    <li>
                      <Link to="/zelf-samenstellen">Zelf samenstellen</Link>
                    </li>
                  </ul>
                </Widget>
              </div>
            </Wrapper>
            <Copyright url={site.siteMetadata.companyUrl} />
          </div>
        </div>
      </div>
    )}
  />
)
