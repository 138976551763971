import React from 'react'
import Helmet from 'react-helmet'

export default ({ description, title, noScroll }) => {
  return (
    <Helmet
      htmlAttributes={{
        lang: 'nl',
        class: noScroll ? 'no-scroll' : '',
      }}
      title={title}
      titleTemplate={`%s | JoyJoy campers`}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: 'JoyJoy',
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
      ]}
    />
  )
}
